import React, { useMemo } from 'react';
import { ROUTES } from '../../../router';
import { useNavigate } from 'react-router-dom';
import { useThemedComponent } from '../../../providers/ThemeProvider';
import SideBarStyles from './SideBarStyles';
import { ReactComponent as Home } from '../../../assets/Home.svg';
import { ReactComponent as UserPlus } from '../../../assets/UserPlus.svg';
import { ReactComponent as Bug } from '../../../assets/Bug.svg';
import { useAuth } from '../../../providers/AuthProvider';
import { StrokeWearRoles } from '../../../types/cloud';

interface SideBarProps {
  style?: React.CSSProperties;
}

const SideBar = (props: SideBarProps) => {
  const navigate = useNavigate();
  const { styles } = useThemedComponent([SideBarStyles]);
  const { roles } = useAuth();

  const canSetInternalRoles = useMemo(() => {
    return roles?.includes(StrokeWearRoles.InternalCreationManager);
  }, [roles]);

  const canDebugSensors = useMemo(() => {
    return roles?.includes(StrokeWearRoles.Developer);
  }, [roles]);

  return (
    <nav style={{ ...styles.nav, ...props.style }}>
      <ul style={{ ...styles.ul }}>
        <li style={{ ...styles.li }}>
          <Home
            style={styles.btn}
            onClick={() => {
              navigate(ROUTES.HOME.path);
            }}
          />
          {!canSetInternalRoles ? null : (
            <UserPlus
              style={styles.btn}
              onClick={() => {
                navigate(ROUTES.SET_INTERNAL_ROLES.path);
              }}
            />
          )}
          {!canDebugSensors ? null : (
            <Bug
              style={styles.btn}
              onClick={() => {
                navigate(ROUTES.DEBUG_SENSOR.path);
              }}
            />
          )}
        </li>
      </ul>
    </nav>
  );
};

export default SideBar;
