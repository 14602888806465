import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { route } from 'react-router-typesafe-routes/dom'; // Or /native
import Home from './pages/authorized/Home/Home';
import Subject from './pages/authorized/Subject/Subject';
import { loader as subjectLoader } from './pages/authorized/Subject/Subject';
import { AuthProvider } from './providers/AuthProvider';
import App from './App';
import Login from './pages/unauthorized/Login/Login';
import CurrentDAAPPage, {
  loader as daapLoader,
} from './pages/authorized/DAAPs/CurrentDAAPPage';
import NewDAAPPage from './pages/authorized/DAAPs/NewDAAPPage';
import GDMPage, { loader as gdmLoader } from './pages/authorized/GDMs/GDMPage';
import NewGDMPage from './pages/authorized/GDMs/NewGDMPage';
import HEPPage, { loader as hepLoader } from './pages/authorized/HEPs/HEPPage';
import Compliance, {
  loader as complianceLoader,
} from './pages/authorized/Compliance/Compliance';
import { RouterLoadingProvider } from './providers/RouterLoadingProvider';
import CreateSubject from './pages/authorized/CreateSubject/CreateSubject';
import AddSensor from './pages/authorized/AddSensor/AddSensor';
import Google from './pages/auth/Google';
import SetExternalUserRoles from './pages/authorized/SetExternalUserRoles/SetExternalUserRoles';
import UpdateSensors from './pages/authorized/UpdateSensors/UpdateSensors';
import SetInternalUserRoles from './pages/authorized/SetInternalUserRoles/SetInternalUserRoles';
import Microsoft from './pages/auth/Micosoft';
import NotFound from './pages/unauthorized/NotFound/NotFound';
import { CloudProvider } from './providers/CloudProvider';
import UpdateSubjectInfo from './pages/authorized/UpdateSubjectInfo/UpdateSubjectInfo';
import ProtectedRoute, { ROUTES_TYPE } from './routes/ProtectedRoute';
import SingleDAAPHistoryPage, {
  loader as daapHistoryLoader,
} from './pages/authorized/DAAPs/SingleDAAPHistoryPage';
import { LoggingProvider } from './providers/LoggingProvider';
import { SubjectProvider } from './providers/SubjectContext';
import TooManyRequests from './pages/unauthorized/TooManyRequests/TooManyRequests';
import DebugSensor from './pages/authorized/DebugSensor/DebugSensor';

const authorizedStack = (
  <RouterLoadingProvider>
    <LoggingProvider>
      <AuthProvider>
        <CloudProvider>
          <SubjectProvider>
            <App />
          </SubjectProvider>
        </CloudProvider>
      </AuthProvider>
    </LoggingProvider>
  </RouterLoadingProvider>
);

const unauthorizedStack = (
  <LoggingProvider>
    <AuthProvider>
      <Login />
    </AuthProvider>
  </LoggingProvider>
);

const googleStack = (
  <LoggingProvider>
    <AuthProvider>
      <Google />
    </AuthProvider>
  </LoggingProvider>
);

const microsoftStack = (
  <LoggingProvider>
    <AuthProvider>
      <Microsoft />
    </AuthProvider>
  </LoggingProvider>
);

export const ROUTES = {
  HOME: route(''),
  UNASSIGNED_USER: route('unassignedUser'),
  SUBJECT: route('subject/:subjectId'),
  UPDATE_INFO: route('subject/:subjectId/updateInfo'),
  DAAPS: route('subject/:subjectId/daaps'),
  NEW_DAAP: route('subject/:subjectId/daaps/new'),
  SINGLE_DAAP: route('subject/:subjectId/daaps/:daapId'),
  GDMS: route('subject/:subjectId/gdm'),
  NEW_GDM: route('subject/:subjectId/gdm/new'),
  HEPS: route('subject/:subjectId/hep'),
  COMPLIANCE: route('subject/:subjectId/compliance'),
  UPDATE_SENSORS: route('subject/:subjectId/updateSensors'),
  LOGIN: route('login'),
  CREATE_USER: route('createSubject'),
  SET_EXTERNAL_ROLES: route('setRoles/external'),
  SET_INTERNAL_ROLES: route('setRoles/internal'),
  DEBUG_SENSOR: route('debug'),
  ADD_SENSOR: route('addSensor'),
  OAUTH_GOOGLE_REDIRECT: route('oauth/google/code'),
  OAUTH_MICROSOFT_REDIRECT: route('oauth/microsoft/code'),
  COMPLETED_DAAP_HISTORY: route('subject/:subjectId/daaps/:daapId'),
  ERROR_TOO_MANY_REQUESTS: route('error'),
};

// If the relative url changes it must also be updated in 'homepage' in the package.json to build correctly
export const PORTAL_RELATIVE_URL = '/portal';

const SHARED_ROUTES = [
  {
    path: ROUTES.SUBJECT.path,
    element: <Subject />,
    loader: subjectLoader,
  },
  {
    path: ROUTES.DAAPS.path,
    element: <CurrentDAAPPage />,
    loader: daapLoader,
  },
  {
    path: ROUTES.NEW_DAAP.path,
    element: <NewDAAPPage />,
    loader: daapLoader,
  },
  {
    path: ROUTES.COMPLETED_DAAP_HISTORY.path,
    element: <SingleDAAPHistoryPage />,
    loader: daapHistoryLoader,
  },
  {
    path: ROUTES.GDMS.path,
    element: <GDMPage />,
    loader: gdmLoader,
  },
  {
    path: ROUTES.NEW_GDM.path,
    element: <NewGDMPage />,
    loader: gdmLoader,
  },
  {
    path: ROUTES.HEPS.path,
    element: <HEPPage />,
    loader: hepLoader,
  },
  {
    path: ROUTES.COMPLIANCE.path,
    element: <Compliance />,
    loader: complianceLoader,
  },
  {
    path: ROUTES.CREATE_USER.path,
    element: <CreateSubject />,
  },
  {
    path: ROUTES.UPDATE_SENSORS.path,
    element: <UpdateSensors />,
    loader: subjectLoader,
  },
  {
    path: ROUTES.ERROR_TOO_MANY_REQUESTS.path,
    element: <TooManyRequests />,
  },
  // add more routes here for shared pages
];

const INTERNAL_ROUTES = [
  {
    path: ROUTES.UPDATE_INFO.path,
    element: <UpdateSubjectInfo />,
    loader: subjectLoader,
  },
  {
    path: ROUTES.SET_EXTERNAL_ROLES.path,
    element: <SetExternalUserRoles />,
  },
  {
    path: ROUTES.ADD_SENSOR.path,
    element: <AddSensor />,
  },
  // add more routes here for internal users
];

const INTERNAL_CREATION_ROUTES = [
  {
    path: ROUTES.SET_INTERNAL_ROLES.path,
    element: <SetInternalUserRoles />,
  },
  {
    path: ROUTES.ERROR_TOO_MANY_REQUESTS.path,
    element: <TooManyRequests />,
  },
  // add more routes here for internal creation manager users
];

const DEVELOPER_ROUTES = [
  {
    path: ROUTES.DEBUG_SENSOR.path,
    element: <DebugSensor />,
  },
  {
    path: ROUTES.ERROR_TOO_MANY_REQUESTS.path,
    element: <TooManyRequests />,
  },
  // add more routes here for developer users
];

export const router = createBrowserRouter(
  [
    {
      path: ROUTES.HOME.path,
      element: authorizedStack,
      errorElement: <NotFound />,
      children: [
        {
          element: (
            <ProtectedRoute
              allowedRoles={[
                ...ROUTES_TYPE.shared,
                ...ROUTES_TYPE.internalCreationManagerOnly,
              ]}
            />
          ),
          children: [
            {
              path: '',
              element: <Home />,
            },
          ],
        },
        {
          element: <ProtectedRoute allowedRoles={ROUTES_TYPE.shared} />,
          children: SHARED_ROUTES,
        },
        {
          element: <ProtectedRoute allowedRoles={ROUTES_TYPE.internal} />,
          children: INTERNAL_ROUTES,
        },
        {
          element: <ProtectedRoute allowedRoles={ROUTES_TYPE.developer} />,
          children: DEVELOPER_ROUTES,
        },
        {
          element: (
            <ProtectedRoute
              allowedRoles={ROUTES_TYPE.internalCreationManagerOnly}
            />
          ),
          children: INTERNAL_CREATION_ROUTES,
        },
      ],
    },
    {
      path: ROUTES.LOGIN.path,
      element: unauthorizedStack,
      errorElement: <NotFound />,
    },
    {
      path: ROUTES.OAUTH_GOOGLE_REDIRECT.path,
      element: googleStack,
      errorElement: <NotFound />,
    },
    {
      path: ROUTES.OAUTH_MICROSOFT_REDIRECT.path,
      element: microsoftStack,
      errorElement: <NotFound />,
    },
  ],
  { basename: PORTAL_RELATIVE_URL },
);
