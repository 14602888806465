import { useMemo } from 'react';
import useErrorHandling from '../hooks/useErrorHandling';
import Parse from '../parse';
import { OwnableObject } from './interfaces/OwnableObject';

export enum LiveQueryPushType {
  /**
   * Start scanning for BLE devices for a specified duration.
   * Params: {duration: number} Default: 10 seconds, this param is in seconds
   */
  BLE_SCAN = 'BLE_SCAN',
  /**
   * Start scanning for BLE devices for a specified duration with a filter.
   * Params: {filter: string, duration: number}. If blank, NO filter is applied, not even the default filter.
   */
  BLE_SCAN_FILTERED = 'BLE_SCAN_FILTERED',
  /**
   * Stop scanning
   */
  BLE_SCAN_STOP = 'BLE_SCAN_STOP',
  /**
   * Connect to a BLE device with a specific MAC address. If the app doesn't recognize
   * the sensor, it will scan for the device first.
   * Params: {mac: string}
   */
  BLE_CONNECT = 'BLE_CONNECT_MAC',
  /**
   * Disconnect from a BLE device with a specific MAC address.
   * Params: {mac: string}
   */
  BLE_DISCONNECT = 'BLE_DISCONNECT_MAC',
  /**
   * Get the current state of the central manager.
   */
  BLE_GET_CENTRAL_MANAGER_STATE = 'BLE_GET_CENTRAL_MANAGER_STATE',
  /**
   * Get the current permissions status.
   */
  GET_PERMISSIONS = 'GET_PERMISSIONS',
  /**
   * Get the current Redux store.
   */
  GET_REDUX_STORE = 'GET_REDUX_STORE',
  /**
   * Request the usage for the sensor
   * Params: {position: LEFT_WRIST | RIGHT_WRIST}
   */
  BLE_GET_USAGE = 'BLE_GET_USAGE',
  /**
   * Request that the periodic file be downloaded
   * Params: {position: LEFT_WRIST | RIGHT_WRIST, printFile: boolean}
   */
  BLE_GET_PERIODIC_FILE = 'BLE_GET_PERIODIC_FILE',
  /**
   * Get the time from the sensor
   * Params: {position: LEFT_WRIST | RIGHT_WRIST}
   */
  BLE_GET_TIME = 'BLE_GET_TIME',
  /**
   * Set the time on the sensor
   * Params: {position: LEFT_WRIST | RIGHT_WRIST, timestamp?: number} Default: current time
   */
  BLE_SET_TIME = 'BLE_SET_TIME',
  /**
   * Get the side of the sensor (affected vs unaffected)
   * Params: {position: LEFT_WRIST | RIGHT_WRIST}
   */
  BLE_GET_SIDE = 'BLE_GET_SIDE',
  /**
   * Set the side of the sensor (affected vs unaffected)
   * Params: {position: LEFT_WRIST | RIGHT_WRIST, affected: boolean}
   */
  BLE_SET_SIDE = 'BLE_SET_SIDE',
  /**
   * Get the recording state of the sensor
   * Params: {position: LEFT_WRIST | RIGHT_WRIST}
   */
  BLE_GET_RECORDING_STATE = 'BLE_GET_RECORDING_STATE',
  /**
   * Blink the LED on the sensor
   * Params: {position: LEFT_WRIST | RIGHT_WRIST}
   */
  BLE_BLINK_LED = 'BLE_BLINK_LED',
  /**
   * Activate the haptic feedback on the sensor
   * Params: {position: LEFT_WRIST | RIGHT_WRIST}
   */
  BLE_HAPTIC = 'BLE_HAPTIC',
  /**
   * Activate the LED and haptic feedback on the sensor
   * Params: {position: LEFT_WRIST | RIGHT_WRIST}
   */
  BLE_LED_HAPTIC = 'BLE_LED_HAPTIC',
  /**
   * Get the firmware version of the sensor
   * Params: {position: LEFT_WRIST | RIGHT_WRIST}
   */
  BLE_FIRMWARE_VERSION = 'BLE_FIRMWARE_VERSION',
  /**
   * Get the firmware info of the sensor
   * Params: {position: LEFT_WRIST | RIGHT_WRIST}
   */
  BLE_FIRMWARE_INFO = 'BLE_FIRMWARE_INFO',
  /**
   * Take a screenshot
   */
  TAKE_SCREENSHOT = 'TAKE_SCREENSHOT',
}

export interface LiveQueryPushAttributes extends OwnableObject {
  command: LiveQueryPushType;
  args: Record<string, unknown>;
  acknowledged?: Date;
  response?: Record<string, unknown>;
  responseFile?: Parse.File;
}

export const classname = 'LiveQueryPush';

export class LiveQueryPush extends Parse.Object<LiveQueryPushAttributes> {
  constructor(attributes: LiveQueryPushAttributes) {
    super(classname, attributes);
  }
}

Parse.Object.registerSubclass(classname, LiveQueryPush);

export function useLiveQueryPushService() {
  const getPush = useErrorHandling(async (id: string) => {
    const pushQuery = new Parse.Query(LiveQueryPush);
    return await pushQuery.get(id);
  }, []);

  return useMemo(() => ({ getPush }), [getPush]);
}
