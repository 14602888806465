import { useCallback, useEffect, useState } from 'react';
import CreateSubjectStyles from './DebugSensorStyles';
import { useThemedComponent } from '../../../providers/ThemeProvider';
import { Form } from 'react-router-dom';
import { Formik } from 'formik';
import {
  KNOWN_CLOUD_FUNCTIONS,
  useCloudContext,
} from '../../../providers/CloudProvider';
import { useLoading } from '../../../providers/LoadingProvider';
import { ButtonRow } from '../../../components/atoms/ButtonRow/ButtonRow';
import { FormDropdown } from '../../../components/atoms/FormDropdown/FormDropdown';
import { FormInput } from '../../../components/atoms/FormInput/FormInput';
import * as Yup from 'yup';
import { SensorPosition } from '../../../schemas/Sensor';
import { Profile } from '../../../schemas/Profile';
import { SensorHistory } from '../../../schemas/SensorHistory';
import Parse from 'parse';
import {
  LiveQueryPush,
  LiveQueryPushType,
} from '../../../schemas/LiveQueryPush';

interface SubjectSensorOptions {
  // Pending sensors that the app hasn't connected to yet
  pendingLeft?: SensorHistory;
  pendingRight?: SensorHistory;

  // Sensors the app is currently connected to
  connectedLeft?: SensorHistory;
  connectedRight?: SensorHistory;
}

const getSensorLabel = (key: keyof SubjectSensorOptions) => {
  switch (key) {
    case 'pendingLeft':
      return 'Pending Left Sensor';
    case 'pendingRight':
      return 'Pending Right Sensor';
    case 'connectedLeft':
      return 'Last Connected Left Sensor';
    case 'connectedRight':
      return 'Last Connected Right Sensor';
  }
};

const capitalizeWords = (str: string) =>
  str.replace(/\b\w/g, l => l.toUpperCase());

export default function DebugSensor() {
  const { styles } = useThemedComponent([CreateSubjectStyles]);

  const [command, setCommand] = useState<LiveQueryPushType>(
    LiveQueryPushType.BLE_SCAN,
  );

  const [subjects, setSubjects] = useState<Profile[]>([]);
  const [subject, setSubject] = useState<Profile>();
  const [sensors, setSensors] = useState<SubjectSensorOptions>({});

  const { setLoading } = useLoading();
  const { cloudService, profileService } = useCloudContext();

  // Get all active subjects
  const fetchSubjects = useCallback(async () => {
    try {
      const subjects = await profileService.getActiveSubjects();
      setSubjects(subjects);
    } catch (e) {
      alert('Failed to fetch subjects:\n\n' + e);
    }
  }, [profileService]);

  useEffect(() => {
    fetchSubjects();
  }, [fetchSubjects]);

  // Get the sensor history for the given subject
  const fetchSensors = useCallback(async () => {
    if (!subject) {
      setSensors({});
      return;
    }
    try {
      // Pending sensors
      const pendingQuery = new Parse.Query(SensorHistory);
      pendingQuery.equalTo('owner', subject);
      pendingQuery.doesNotExist('unpaired');
      pendingQuery.doesNotExist('connected');
      pendingQuery.exists('paired');
      pendingQuery.include('sensor');
      pendingQuery.descending('createdAt');
      pendingQuery.equalTo('position', SensorPosition.LEFT_WRIST);

      const pendingLeft = await pendingQuery.find();

      pendingQuery.equalTo('position', SensorPosition.RIGHT_WRIST);

      const pendingRight = await pendingQuery.find();

      // Connected sensors
      const query = new Parse.Query(SensorHistory);
      query.equalTo('owner', subject);
      query.exists('connected');
      query.include('sensor');
      query.descending('createdAt');
      query.equalTo('position', SensorPosition.LEFT_WRIST);

      const connectedLeft = await query.find();

      query.equalTo('position', SensorPosition.RIGHT_WRIST);

      const connectedRight = await query.find();

      setSensors({
        pendingLeft: pendingLeft[0],
        pendingRight: pendingRight[0],
        connectedLeft: connectedLeft[0],
        connectedRight: connectedRight[0],
      });
    } catch (e) {
      alert('Failed to fetch sensor history:\n\n' + e);
    }
  }, [subject]);

  useEffect(() => {
    fetchSensors();
  }, [fetchSensors]);

  const displayScreenshot = useCallback(async (push: LiveQueryPush) => {
    const query = new Parse.Query(LiveQueryPush);
    query.equalTo('objectId', push.id);

    return new Promise<void>((resolve, reject) => {
      // Check every 2 seconds for the screenshot for 30 seconds
      const interval = setInterval(async () => {
        try {
          const push = await query.first();
          if (push && push.attributes.responseFile) {
            clearInterval(interval);
            clearTimeout(timeout);
            const url = push.attributes.responseFile.url();
            resolve();

            // Open the screenshot in a new tab
            window.open(url, '_blank');
          }
        } catch (e) {
          alert('Failed to fetch screenshot:\n\n' + e);
        }
      }, 2000);

      const timeout = setTimeout(() => {
        clearInterval(interval);
        reject('Failed to fetch screenshot');
      }, 30000);
    });
  }, []);

  // Handle sending the push
  const onSubmit = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (values: any) => {
      try {
        const valuesCopy = { ...values };
        delete valuesCopy.command;
        delete valuesCopy.subject;
        const params = {
          args: valuesCopy,
          command,
          subjectId: subject?.attributes.subjectId,
        };
        setLoading(true);
        const push: LiveQueryPush = await cloudService.run(
          KNOWN_CLOUD_FUNCTIONS.SEND_LIVE_QUERY_PUSH,
          JSON.parse(JSON.stringify(params)),
        );

        // If a screenshot, wait and display
        if (command === LiveQueryPushType.TAKE_SCREENSHOT) {
          await displayScreenshot(push);
        }
      } catch (e) {
        alert('Failed to send push:\n\n' + e);
      } finally {
        setLoading(false);
      }
    },
    [
      cloudService,
      command,
      displayScreenshot,
      setLoading,
      subject?.attributes.subjectId,
    ],
  );

  const getFields = useCallback((command: LiveQueryPushType) => {
    let title = '';

    const args: Record<
      string,
      {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        schema: Yup.Schema<any>;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        initialValue?: any;
      }
    > = {};

    const sensor = {
      schema: Yup.string().required('Sensor is required'),
      initialValue: '',
    };

    switch (command) {
      case LiveQueryPushType.BLE_SCAN:
        title = 'Start BLE Scan for X seconds';
        args.duration = {
          schema: Yup.number().required('Duration is required'),
          initialValue: 10,
        };
        break;
      case LiveQueryPushType.BLE_SCAN_FILTERED:
        title = 'Start BLE Scan for X seconds with filter';
        args.filter = {
          schema: Yup.string(),
          initialValue: '2edc2545-5224-438b-aa7a-a236594314c2',
        };
        args.duration = {
          schema: Yup.number().required('Duration is required'),
          initialValue: 10,
        };
        break;
      case LiveQueryPushType.BLE_SCAN_STOP:
        title = 'Stop BLE Scan';
        break;
      case LiveQueryPushType.BLE_CONNECT:
        title = 'Connect to a sensor';
        args.sensor = sensor;
        break;
      case LiveQueryPushType.BLE_DISCONNECT:
        title = 'Disconnect from a sensor';
        args.sensor = sensor;
        break;
      case LiveQueryPushType.BLE_GET_CENTRAL_MANAGER_STATE:
        title = 'Print Bluetooth Central Manager State';
        break;
      case LiveQueryPushType.GET_PERMISSIONS:
        title = 'Print App Permissions Status';
        break;
      case LiveQueryPushType.GET_REDUX_STORE:
        title = 'Print Redux Store';
        break;
      case LiveQueryPushType.BLE_GET_USAGE:
        title = 'Print Sensor Usage';
        args.sensor = sensor;
        break;
      case LiveQueryPushType.BLE_GET_PERIODIC_FILE:
        title = 'Request Periodic File From Sensor';
        args.sensor = sensor;
        args.printFile = {
          schema: Yup.boolean(),
          initialValue: false,
        };
        break;
      case LiveQueryPushType.BLE_GET_TIME:
        title = 'Print Sensor Time';
        args.sensor = sensor;
        break;
      case LiveQueryPushType.BLE_SET_TIME:
        title = 'Set Sensor Time';
        args.sensor = sensor;
        args.timestamp = {
          schema: Yup.number(),
          initialValue: Date.now(),
        };
        break;
      case LiveQueryPushType.BLE_GET_SIDE:
        title = 'Print Sensor Affected Side';
        args.sensor = sensor;
        break;
      case LiveQueryPushType.BLE_SET_SIDE:
        title = 'Set Sensor Affected Side';
        args.sensor = sensor;
        args.affected = {
          schema: Yup.boolean(),
          initialValue: false,
        };
        break;
      case LiveQueryPushType.BLE_GET_RECORDING_STATE:
        title = 'Print Sensor Recording State';
        args.sensor = sensor;
        break;
      case LiveQueryPushType.BLE_BLINK_LED:
        title = 'Blink Sensor LED';
        args.sensor = sensor;
        break;
      case LiveQueryPushType.BLE_HAPTIC:
        title = 'Buzz Sensor';
        args.sensor = sensor;
        break;
      case LiveQueryPushType.BLE_LED_HAPTIC:
        title = 'Buzz Sensor and Blink LED';
        args.sensor = sensor;
        break;
      case LiveQueryPushType.BLE_FIRMWARE_VERSION:
        title = 'Print Sensor Firmware Version';
        args.sensor = sensor;
        break;
      case LiveQueryPushType.BLE_FIRMWARE_INFO:
        title = 'Print Sensor Firmware Info';
        args.sensor = sensor;
        break;
      case LiveQueryPushType.TAKE_SCREENSHOT:
        title = 'Take Screenshot';
        break;
    }

    const schema = Yup.object().shape(
      Object.fromEntries(
        Object.entries(args).map(([key, value]) => [key, value.schema]),
      ),
    );

    const initialValues = Object.fromEntries(
      Object.entries(args).map(([key, value]) => [key, value.initialValue]),
    );

    return { title, schema, initialValues };
  }, []);

  return (
    <div style={styles.pageContainer}>
      <div style={styles.pageHeader}>
        <h1 style={styles.h1}>Send Sensor Push Command</h1>
      </div>
      <div style={styles.topContainer}>
        <Formik
          initialValues={getFields(command).initialValues}
          validationSchema={Yup.object().shape({
            ...getFields(command).schema.fields,
          })}
          onSubmit={onSubmit}>
          {({ isSubmitting, handleSubmit, values, resetForm }) => {
            const fields = getFields(values.command as LiveQueryPushType);

            return (
              <Form style={styles.container} onSubmit={handleSubmit}>
                <FormDropdown
                  name="command"
                  label="Command"
                  placeholder="Select Command"
                  options={Object.values(LiveQueryPushType).map(r => ({
                    value: r,
                    label: getFields(r).title,
                  }))}
                  onChange={e => {
                    setCommand(e as LiveQueryPushType);
                    resetForm({
                      values: {
                        command: e,
                        ...getFields(e as LiveQueryPushType).initialValues,
                      },
                    });
                  }}
                />
                <FormDropdown
                  name="subject"
                  label="Subject"
                  placeholder="Select Subject"
                  options={subjects.map(r => ({
                    value: r,
                    label: r.attributes.subjectId,
                  }))}
                  onChange={setSubject}
                />

                {Object.entries(fields.initialValues).map(([key]) => {
                  if (key === 'sensor') {
                    return (
                      <FormDropdown
                        key={key + subject?.id}
                        name={key}
                        options={Object.entries(sensors)
                          // eslint-disable-next-line @typescript-eslint/no-unused-vars
                          .filter(([_, v]) => !!v)
                          .map(([key, value]) => ({
                            value: value.attributes.sensor.attributes.mac,
                            label: `${value.attributes.sensor.attributes.mac} - ${getSensorLabel(
                              key as keyof SubjectSensorOptions,
                            )}`,
                          }))}
                        label="Sensor"
                        placeholder="Select Sensor"
                      />
                    );
                  } else {
                    return (
                      <FormInput
                        key={key}
                        type="text"
                        name={key}
                        label={capitalizeWords(key)}
                        placeholder={capitalizeWords(key)}
                      />
                    );
                  }
                })}

                <ButtonRow
                  buttons={[
                    {
                      title: 'Send',
                      type: 'submit',
                      disabled: isSubmitting,
                      style: styles.saveButton,
                    },
                  ]}
                  style={styles.buttonContainer}
                />
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}
