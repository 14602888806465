import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
} from 'react';

import Parse from '../parse';
import { useActionPlanService } from '../schemas/ActionPlan';
import { useAppEventService } from '../schemas/AppEvent';
import { useExerciseService } from '../schemas/Exercise';
import { useGoalService } from '../schemas/Goal';
import { useMovementService } from '../schemas/Movement';
import { useProfileService } from '../schemas/Profile';
import { useSensorService } from '../schemas/Sensor';
import { useSensorEventService } from '../schemas/SensorEvent';
import { useSensorHistoryService } from '../schemas/SensorHistory';
import useErrorHandling from '../hooks/useErrorHandling';
import {
  CompletedDAAPHistoryRequest,
  CompletedDAAPHistoryResponse,
  CompletedDAAPRequest,
  CompletedDAAPResponse,
  CurrentDAAPGoalColumns,
  CurrentDAAPRequest,
  DAAPGoalHistoryColumns,
  DAAPRHistoryRequest,
  GDMHourlyColumns,
  GDMHourlyRequest,
  GDMInfoRequest,
  GDMInformationColumns,
  HEPInfoRequest,
  HEPInformationColumns,
  HistoricalSensorColumns,
  HomeSummaryResponse,
  SensorHistoryRequest,
  SensorWearDailyRequest,
  SensorWearHoursDailyColumns,
  SensorWearHoursWeeklyColumns,
  SensorWearWeeklyRequest,
  SubjectSummaryRequest,
  SubjectSummaryTableColumns,
  TableRequest,
  TableResponse,
} from '../types/tables';
import { StrokeWearRoles, UserTemporaryAuthToken } from '../types/cloud';
import { useGDMInfoService } from '../schemas/GDMInfo';
import { useLogging } from './LoggingProvider';
import { useLiveQueryPushService } from '../schemas/LiveQueryPush';

/**
 * Known cloud functions that can be run
 */
export const KNOWN_CLOUD_FUNCTIONS = {
  PING: 'ping',
  SET_SECONDARY_THERAPIST: 'SetSecondaryTherapist',
  SUBJECT_SUMMARY_TABLE: 'SubjectSummaryTable',
  HOME_SUMMARY_TABLE: 'HomeSummaryTable',
  SENSOR_HISTORY_TABLE: 'SensorHistoryTable',
  GDM_INFO_TABLE: 'GDMInformationTable',
  GDM_HOURLY_TABLE: 'GDMHourlyTable',
  HEP_INFO_TABLE: 'HEPInformationTable',
  SWAP_SENSOR: 'SwapSensor',
  GET_NON_SUBJECT_USERS: 'GetNonSubjectUsers',
  GRANT_OR_REVOKE_INTERNAL_ROLE: 'GrantOrRevokeInternalRole',
  GRANT_OR_REVOKE_EXTERNAL_ROLE: 'GrantOrRevokeExternalRole',
  CREATE_PROFILE: 'CreateProfile',
  UPDATE_PROFILE: 'UpdateProfile',
  GENERATE_LOGIN_TOKEN: 'GenerateLoginToken',
  GET_USERS_WITH_ROLE: 'GetUsersWithRole',
  ADD_OR_REMOVE_SENSOR: 'AddOrRemoveSensor',
  CURRENT_DAAP_TABLE: 'CurrentDAAPTable',
  COMPLETED_DAAP_TABLE: 'CompletedDAAPTable',
  DAAP_HISTORY_TABLE: 'DAAPHistoryTable',
  SENSOR_WEAR_WEEKLY_TABLE: 'SensorWearWeeklyTable',
  SENSOR_WEAR_DAILY_TABLE: 'SensorWearDailyTable',
  COMPLETED_DAAP_HISTORY_TABLE: 'CompletedDAAPHistoryTable',
  SEND_LIVE_QUERY_PUSH: 'SendLiveQueryPush',
} as const;

interface HomeSummaryRequest extends TableRequest {
  active: boolean;
}

export type CloudContent = {
  actionPlanService: ReturnType<typeof useActionPlanService>;
  appEventService: ReturnType<typeof useAppEventService>;
  exerciseService: ReturnType<typeof useExerciseService>;
  goalService: ReturnType<typeof useGoalService>;
  movementService: ReturnType<typeof useMovementService>;
  profileService: ReturnType<typeof useProfileService>;
  sensorService: ReturnType<typeof useSensorService>;
  sensorEventService: ReturnType<typeof useSensorEventService>;
  sensorHistoryService: ReturnType<typeof useSensorHistoryService>;
  cloudService: ReturnType<typeof useCloudService>;
  gdmInfoService: ReturnType<typeof useGDMInfoService>;
  liveQueryPushService: ReturnType<typeof useLiveQueryPushService>;
};

const CloudContext = createContext<CloudContent>(undefined!);

const CloudProvider = ({ children }: { children: ReactNode }) => {
  const actionPlanService = useActionPlanService();
  const appEventService = useAppEventService();
  const exerciseService = useExerciseService();
  const goalService = useGoalService();
  const movementService = useMovementService();
  const profileService = useProfileService();
  const sensorService = useSensorService();
  const sensorEventService = useSensorEventService();
  const sensorHistoryService = useSensorHistoryService();
  const cloudService = useCloudService();
  const gdmInfoService = useGDMInfoService();
  const liveQueryPushService = useLiveQueryPushService();

  return (
    <CloudContext.Provider
      value={{
        actionPlanService,
        appEventService,
        exerciseService,
        goalService,
        movementService,
        profileService,
        sensorService,
        sensorEventService,
        sensorHistoryService,
        cloudService,
        gdmInfoService,
        liveQueryPushService,
      }}>
      {children}
    </CloudContext.Provider>
  );
};

const useCloudContext = () => useContext(CloudContext);

export { useCloudContext, CloudProvider };

function useCloudService() {
  const logger = useLogging(useCloudService.name);

  const run = useErrorHandling(
    async <T,>(
      functionName: string,
      params?: JSON,
      log: boolean = true,
    ): Promise<T> => {
      if (log) {
        logger.debug(
          `Running cloud function ${functionName} with params ${JSON.stringify(params)}`,
        );
      }
      return Parse.Cloud.run(functionName, params);
    },
    [logger],
  );

  const prepareRquest = useCallback(<T extends object>(req: T) => {
    return JSON.parse(JSON.stringify(req));
  }, []);

  /**
   * Quick helper function to prepare a request for the cloud function
   * @param req The request to prepare
   * @returns A JSON object that can be sent to the cloud function
   */
  const prepareTableRquest = useCallback(
    <T extends TableRequest>(req: T) => {
      return prepareRquest(req);
    },
    [prepareRquest],
  );

  const getServerTime = useCallback(async (): Promise<Date> => {
    const time = await run<string>(KNOWN_CLOUD_FUNCTIONS.PING);
    return new Date(time);
  }, [run]);

  /**
   * Get the subject summary table
   * @param req The request to get the subject summary
   * @returns The response from the cloud function
   */
  const requestSubjectSummary = useCallback(
    async (req: SubjectSummaryRequest) => {
      const res = await run<Promise<TableResponse<SubjectSummaryTableColumns>>>(
        KNOWN_CLOUD_FUNCTIONS.SUBJECT_SUMMARY_TABLE,
        prepareTableRquest(req),
      );
      return res;
    },
    [prepareTableRquest, run],
  );

  const requestHomeSummary = useCallback(
    async (req: HomeSummaryRequest) => {
      const res = await run<Promise<HomeSummaryResponse>>(
        KNOWN_CLOUD_FUNCTIONS.HOME_SUMMARY_TABLE,
        prepareTableRquest(req),
      );
      return res;
    },
    [prepareTableRquest, run],
  );

  const requestSensorHistory = useCallback(
    async (req: SensorHistoryRequest) => {
      const res = await run<Promise<TableResponse<HistoricalSensorColumns>>>(
        KNOWN_CLOUD_FUNCTIONS.SENSOR_HISTORY_TABLE,
        prepareTableRquest(req),
      );

      return res;
    },
    [prepareTableRquest, run],
  );

  const requestGDMInfo = useCallback(
    async (req: GDMInfoRequest) => {
      const res = await run<Promise<TableResponse<GDMInformationColumns>>>(
        KNOWN_CLOUD_FUNCTIONS.GDM_INFO_TABLE,
        prepareTableRquest(req),
      );

      return res;
    },
    [prepareTableRquest, run],
  );

  const requestGDMHourly = useCallback(
    async (req: GDMHourlyRequest) => {
      const res = await run<Promise<TableResponse<GDMHourlyColumns>>>(
        KNOWN_CLOUD_FUNCTIONS.GDM_HOURLY_TABLE,
        prepareTableRquest(req),
      );

      return res;
    },
    [prepareTableRquest, run],
  );

  const getSubjectToken = useCallback(
    async (subjectId: string) => {
      const res = await run<Parse.Object<UserTemporaryAuthToken>>(
        KNOWN_CLOUD_FUNCTIONS.GENERATE_LOGIN_TOKEN,
        prepareRquest({ subjectId }),
      );
      return res;
    },
    [prepareRquest, run],
  );

  const getUsersWithRole = useCallback(
    async (role: StrokeWearRoles) => {
      const res = await run<Promise<Array<Parse.Object<Parse.Attributes>>>>(
        KNOWN_CLOUD_FUNCTIONS.GET_USERS_WITH_ROLE,
        prepareRquest({ role }),
      );
      return res;
    },
    [prepareRquest, run],
  );

  const requestHEPInfo = useCallback(
    async (req: HEPInfoRequest) => {
      const res = await run<Promise<TableResponse<HEPInformationColumns>>>(
        KNOWN_CLOUD_FUNCTIONS.HEP_INFO_TABLE,
        prepareTableRquest(req),
      );

      return res;
    },
    [prepareTableRquest, run],
  );

  const requestCurrentDAAPTable = useCallback(
    async (req: CurrentDAAPRequest) => {
      const res = await run<Promise<TableResponse<CurrentDAAPGoalColumns>>>(
        KNOWN_CLOUD_FUNCTIONS.CURRENT_DAAP_TABLE,
        prepareTableRquest(req),
      );

      return res;
    },
    [prepareTableRquest, run],
  );

  const requestCompletedDAAPTable = useCallback(
    async (req: CompletedDAAPRequest) => {
      const res = await run<Promise<CompletedDAAPResponse>>(
        KNOWN_CLOUD_FUNCTIONS.COMPLETED_DAAP_TABLE,
        prepareTableRquest(req),
      );

      return res;
    },
    [prepareTableRquest, run],
  );

  const requestDAAPHistoryTable = useCallback(
    async (req: DAAPRHistoryRequest) => {
      const res = await run<Promise<TableResponse<DAAPGoalHistoryColumns>>>(
        KNOWN_CLOUD_FUNCTIONS.DAAP_HISTORY_TABLE,
        prepareTableRquest(req),
      );

      return res;
    },
    [prepareTableRquest, run],
  );

  const requestCompletedDAAPHistoryTable = useCallback(
    async (req: CompletedDAAPHistoryRequest) => {
      const res = await run<Promise<CompletedDAAPHistoryResponse>>(
        KNOWN_CLOUD_FUNCTIONS.COMPLETED_DAAP_HISTORY_TABLE,
        prepareTableRquest(req),
      );

      return res;
    },
    [prepareTableRquest, run],
  );

  const requestSensorWearWeeklyTable = useCallback(
    async (req: SensorWearWeeklyRequest) => {
      const res = await run<
        Promise<TableResponse<SensorWearHoursWeeklyColumns>>
      >(
        KNOWN_CLOUD_FUNCTIONS.SENSOR_WEAR_WEEKLY_TABLE,
        prepareTableRquest(req),
      );
      return res;
    },
    [prepareTableRquest, run],
  );

  const requestSensorWearDailyTable = useCallback(
    async (req: SensorWearDailyRequest) => {
      const res = await run<
        Promise<TableResponse<SensorWearHoursDailyColumns>>
      >(KNOWN_CLOUD_FUNCTIONS.SENSOR_WEAR_DAILY_TABLE, prepareTableRquest(req));

      return res;
    },
    [prepareTableRquest, run],
  );

  return useMemo(
    () => ({
      run,
      getServerTime,
      requestSubjectSummary,
      requestHomeSummary,
      requestSensorHistory,
      requestGDMInfo,
      requestGDMHourly,
      requestHEPInfo,
      getSubjectToken,
      getUsersWithRole,
      requestCurrentDAAPTable,
      requestCompletedDAAPTable,
      requestDAAPHistoryTable,
      requestSensorWearWeeklyTable,
      requestSensorWearDailyTable,
      requestCompletedDAAPHistoryTable,
    }),
    [
      getServerTime,
      getSubjectToken,
      getUsersWithRole,
      requestCompletedDAAPHistoryTable,
      requestCompletedDAAPTable,
      requestCurrentDAAPTable,
      requestDAAPHistoryTable,
      requestGDMHourly,
      requestGDMInfo,
      requestHEPInfo,
      requestHomeSummary,
      requestSensorHistory,
      requestSensorWearDailyTable,
      requestSensorWearWeeklyTable,
      requestSubjectSummary,
      run,
    ],
  );
}
