import { type StyleSheet } from '../../../providers/ThemeProvider';

export default (): StyleSheet => ({
  topContainer: {
    marginTop: '2vmin',
    width: '70%',
  },

  buttonContainer: {
    display: 'flex',
    gap: 10,
    marginTop: 20,
    width: '100%',
  },
});
