import { Outlet, useOutletContext } from 'react-router-dom';
import { useAuth } from '../providers/AuthProvider';
import { StrokeWearRoles } from '../types/cloud';
import { useEffect, useMemo } from 'react';
import { useLoading } from '../providers/LoadingProvider';
import NotFound from '../pages/unauthorized/NotFound/NotFound';
import UnassignedUser from '../pages/unauthorized/UnassignedUser/UnassignedUser';

export const ROUTES_TYPE = {
  // only no role users should be able to access unassigned page
  noRoles: [],
  // every authorized person can access, except subjects and internal creation managers
  shared: Object.values(StrokeWearRoles).filter(
    role =>
      role !== StrokeWearRoles.InternalCreationManager &&
      role !== StrokeWearRoles.InternalCreationManagerRead &&
      role !== StrokeWearRoles.InternalCreationManagerWrite &&
      role !== StrokeWearRoles.Subject &&
      role !== StrokeWearRoles.SubjectRead &&
      role !== StrokeWearRoles.SubjectWrite,
  ),
  // operation managers
  internal: [StrokeWearRoles.OperationsManager],
  // internal creation manager only
  internalCreationManagerOnly: [StrokeWearRoles.InternalCreationManager],
  // therapists and study coordinators
  external: [StrokeWearRoles.StudyCoordinator, StrokeWearRoles.Therapist],
  // developers
  developer: [StrokeWearRoles.Developer],
} as const;

interface ProtectedRouteProps {
  allowedRoles: readonly StrokeWearRoles[];
}

const ProtectedRoute = ({ allowedRoles }: ProtectedRouteProps) => {
  const { roles } = useAuth();
  const { setLoading } = useLoading();
  const context = useOutletContext();

  useEffect(() => {
    if (roles === undefined) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [roles, setLoading]);

  const routeWithRoles = useMemo(() => {
    if (roles === undefined) {
      return;
    }

    if (roles.length === 0) {
      return <UnassignedUser />;
    }

    if (allowedRoles.every(role => !roles.includes(role))) {
      return <NotFound />;
    }

    return <Outlet context={context} />;
  }, [allowedRoles, context, roles]);

  return <>{routeWithRoles}</>;
};

export default ProtectedRoute;
