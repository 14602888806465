import React, { HTMLProps, useEffect, useState } from 'react';
import { useField } from 'formik';
import {
  type ThemeContent,
  type StyleSheet,
  useThemedComponent,
} from '../../../providers/ThemeProvider';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';

interface FormFieldProps extends HTMLProps<HTMLInputElement> {
  type: string;
  name: string;
  initialValue?: string;
  placeholder?: string;
  label: string;
  styles?: StyleSheet;
  labelStyle?: React.CSSProperties;
  // helper to manipulate barcode scanner data
  transformInput?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const FormInput = ({
  type,
  name,
  label,
  initialValue,
  placeholder,
  autoFocus,
  ...props
}: FormFieldProps) => {
  const { styles } = useThemedComponent(FormInputStyles);
  const [field, meta] = useField({ name });
  const [error, setError] = useState<boolean>();

  useEffect(() => {
    setError(meta.error !== undefined && meta.touched);
  }, [meta.error, meta.touched]);

  return (
    <div>
      <div style={styles.fieldContainer}>
        <div style={styles.labelContainer}>
          <label style={{ ...styles.label, ...props.labelStyle }}>
            {label}
          </label>
        </div>
        <div style={styles.inputContainer}>
          <input
            autoFocus={autoFocus}
            type={type}
            placeholder={placeholder}
            {...field}
            value={field.value ?? initialValue}
            style={{ ...styles.input, ...(error ? styles.inputError : {}) }}
            onChange={event => {
              const newEvent = props.transformInput
                ? props.transformInput(event)
                : event;
              field.onChange(newEvent);
            }}
            onKeyDown={props.onKeyDown}
          />
          {
            // to not overlay the red icon on default arrows of input type number
            error && type !== 'number' && (
              <ExclamationCircleIcon style={styles.errorIcon} />
            )
          }
        </div>
      </div>
      <div style={styles.fieldContainer}>
        <div style={styles.labelContainer} />
        {error && (
          <div style={styles.errorContainer}>
            <span style={styles.errorText}>{meta.error}</span>
          </div>
        )}
      </div>
    </div>
  );
};

const FormInputStyles = (theme: ThemeContent): StyleSheet => ({
  fieldContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
  labelContainer: {
    flex: 1,
  },
  inputContainer: {
    flex: 2,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    position: 'relative',
  },
  input: {
    width: '100%',
    height: 35,
    borderRadius: 5,
    borderWidth: '2px',
    position: 'relative',
    borderStyle: 'solid',
    borderColor: theme.colors.coolGrey,
    paddingLeft: 5,
  },
  inputError: {
    borderColor: theme.colors.red,
    borderWidth: '2px',
  },
  errorContainer: {
    flex: 2,
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
  },
  errorText: {
    color: theme.colors.red,
  },
  placeholderText: {
    color: theme.colors.greySecondary,
  },
  label: {
    fontWeight: 'bold',
  },
  errorIcon: {
    position: 'absolute',
    height: '1rem',
    width: '1rem',
    color: 'red',
    marginRight: '3%',
  },
});
